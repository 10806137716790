import { template as template_1246d0d307fe4447bb0e0825846e6f9a } from "@ember/template-compiler";
const FKLabel = template_1246d0d307fe4447bb0e0825846e6f9a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
