import { template as template_8acebdce768f446293993ef399d16eeb } from "@ember/template-compiler";
const SidebarSectionMessage = template_8acebdce768f446293993ef399d16eeb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
